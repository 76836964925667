<template>
	<div class="my">
		<div class="top flex-between">
			<div class="tleft">
				<img :src="userinfo.avatar || 'http://yzhs-1253640091.cos.ap-shanghai.myqcloud.com/72c9a60f47b5f767c871e3a113e85f4e.png'" alt="">
				<div>
					<p>{{account}}</p>
					<p>
						<img :src="vipimg[userinfo.level-1]" style="width:75px;height:22px">
						<span @click="tourl('/quyou/rights')" class="hand">会员说明</span>
					</p>
				</div>
			</div>
			<div class="tright">
				<!-- <div @click="tourl('/user/mytravel')" class="hand">
					<p>{{userinfo.article_count}}</p>
					<p>游记</p>
				</div>
				<div @click="tourl('/user/myquestion')" class="hand">
					<p>{{userinfo.answe_count}}</p>
					<p>问答</p>
				</div> -->
				<div @click="tourl('/user/coupons')" class="hand">
					<p>{{userinfo.coupon_count}}</p>
					<p>优惠券</p>
				</div>
				<div @click="tourl('/user/integral')" class="hand">
					<p style="color: #FF7C00;">{{userinfo.integral}}</p>
					<p style="color: #FF7C00;">奖励积分</p>
				</div>
				<div  @click="tourl('/user/djintegral')" class="hand">
					<p style="color: #FF7C00;">{{userinfo.level_integral}}</p>
					<p style="color: #FF7C00;">等级积分</p>
				</div>
			</div>
		</div>

		<div class="order">
			<div class="title">
				<h4>近期订单</h4>

			</div>

			<div class="icon">
				<div @click="tourl('/user/order?id=0')">
					<img src="../../assets/user/my1.png">
					<p>待付款 <span>({{userinfo.order_count_0}})</span></p>
				</div>
				<div @click="tourl('/user/order?id=1')">
					<img src="../../assets/user/my2.png" alt="">
					<p>未出行 <span>({{userinfo.order_count_1}})</span></p>
				</div>
				<div @click="tourl('/user/order?id=2')">
					<img src="../../assets/user/my3.png" alt="">
					<p>已出行 <span>({{userinfo.order_count_2}})</span></p>
				</div>
				<div @click="tourl('/user/order?id=3')">
					<img src="../../assets/user/my3.png" alt="">
					<p>已取消 <span>({{userinfo.order_count_3}})</span></p>
				</div>
				<div @click="tourl('/user/order?id=all')">
					<img src="../../assets/user/my3.png" alt="">
					<p>全部订单 <span>({{userinfo.order_count_6}})</span></p>
				</div>
			</div>
		</div>

		<!-- <div class="order">
			<div class="title">
				<h4>我的游记</h4>
				<div class="writ" >
					<img src="../../assets/user/pen.png" alt="">
					<p @click="tourl('/uptrival')">写游记</p>
				</div>
			</div>

			<div class="icon">
				<div @click="tourl('/user/mytravel')">
					<img src="../../assets/user/my6.png" alt="">
					<p>已发布 <span>({{userinfo.article_take_1}})</span></p>
				</div>
				<div @click="tourl('/user/mytravel')">
					<img src="../../assets/user/my7.png" alt="">
					<p>草稿 <span>({{userinfo.article_take_0}})</span></p>
				</div>

			</div>
		</div> -->

		<!--<div class="order">
			<div class="title">
				<h4>我的问答</h4>
				<div class="writ">
					<img src="../../assets/user/mykefu.png" alt="">
					<p class="hard" @click="showmsg">我要咨询</p>
				</div>
			</div>

			 <div class="wd">
				<div class="coulist">
					<div class="couitem" v-for="(item,i) in list" :key="i" >
					
						<div class="c2">
							<div>
								<p><strong>{{item.goods_name}}</strong></p>
								<p> {{item.create_time}}</p>
							</div>
							<p>{{item.question}}</p>
						</div>
						<p class="c3" v-if="item.status==1">
							<span>客服回复:</span>
							{{item.answer}}
						</p>
					</div>

				</div> 


				<p class="nothing" v-if="rowsCount==0">暂时还没有问答呢，快来咨询呀~</p>
			</div>
		</div> -->
	</div>

</template>

<script>
import { userinfoAPI,userQuestionlistAPI } from "@/api/user";
export default {

  data () {
    return {
		userinfo:{},
		vipimg:[],
		list:[],
		rowsCount:1,
		account:localStorage.getItem("QY_USER") 
    }
  },
  mounted(){
	  
  },
  created () {
	//   if (this.$route.query.login=="success") {
	// 	    this.$router.push({ path: '/user/my'})
	//   }
	  this.getinfo()
	  this.getImage()
	  this.getList()
  },
  methods: {
	  showmsg(){
		  this.$message("您可以前往产品详情进行针对性咨询哦")
	  },
	   getImage() {
		for (let index = 1; index < 6; index++) {
			this.vipimg.push(require('../../assets/vip/vip' +index+ '.png'))			
		}
	  },
	 getList(n){
		this.sign=n
		  var _this=this
		  userQuestionlistAPI({pageSize:3,page:1}).then(res=>{
			  _this.list=res.cateList
			  this.rowsCount=res.rowsCount
		  })
	  },
	  getinfo(){
		  var _this=this
		  userinfoAPI().then(res=>{
			  _this.userinfo=res.shareInfo	
			
		  })
	  },
	  tourl(url){
		  this.$router.push({ path: url});
	  },
	  
  }
}
</script>

<style lang="scss" scoped="scoped">
.my{
	background: #ffffff;
	.top{
		background: linear-gradient(266deg, #FFF4E4 9% ,#FEE5CD 86%);
		border-radius: 6px 6px 0 0;
		height: 136px;
		padding: 0 35px;
		.tleft{
			display: flex;
			>img{
				width: 60px;
				height: 60px;
				border-radius: 50%;
			}
			>div{

				margin-left: 10px;
				>p{
					font-weight: 600;
					color: #6d380c;
					line-height: 25px;
				}
				p{
					display: flex;
					align-items: center;
					margin-top: 4px;
					span{
						font-size: 12px;
						color: #666;
						font-weight: 400;
						margin-left: 8px;
					}
				}
			}
		}
		.tright{
			display: flex;
			>div{
				margin: 0 20px;
				text-align: center;
				line-height: 35px;
			}
		}
	}

	.order{
		margin: 5px 20px;
		border-bottom: 1px solid #e6e6e6;
		.title{
			display: flex;
			align-items: center;
			height: 35px;
			margin-top: 15px;
			h4{
				font-size: 18px;
				font-weight: 500;
				color: #333333;
				font-weight: 500;

			}
			.writ{
				display: flex;
				align-items: center;
				margin-left: 20px;
				cursor: pointer;
				p{
					color: #FF7C00;
					margin-left: 5px;

				}
			}
		}

		.icon{
			display: flex;

			margin: 15px 0;
			padding: 10px;
			>div{
				cursor: pointer;
			}
			div{
				width: 140px;
				display: flex;
				align-items: center;
				margin-right: 60px;
				p{
					margin-left: 5px;
					font-size: 14px;
					span{
						color: #FF7C00;
					}
				}
			}
			div:last-child{
				margin-right:0;
			}
		}

		.wd{
			min-height: 100px;
			.nothing{
				text-align: center;
				margin-top:40px;
				color: #666;
			}
		}
	}
}
.coulist{
           .couitem{
            
               padding:10px 0;
           }
           
           .c2{
               line-height: 30px;
               div{
                   display: flex;
				   justify-content: space-between;
				   align-items: center;
                   p{
                       margin-right: 25px;
                       font-size: 12px;
                       color: #999999;
                       strong{
                           font-size: 16px;
                       }
                   }
               }
               >p{
                   font-size: 14px;
                  line-height: 30px;
               }
           }
            .c3{
                line-height: 44px;
                opacity: 1;
                background: #f9f9f9;
                border-radius: 4px;
                padding:3px 10px;
                span{
                    color: #FF7C00;
                    margin-right: 5px;
                    border-radius: 3px;
                }
            }
			

		}
</style>
